// @flow

import React from "react";
import { DefaultLayout, Layout, withPrivateRoute, ZVGTeams } from "@containers";

/**
 * ZVT en Teams
 */
const Zvt = (props: *) => (
    <Layout {...props}>
        <DefaultLayout title="ZVG & Teams">
            <ZVGTeams id="beheer-zvg-teams" />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(Zvt);
